import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from "@/utilities/commonFunctions";
import { getHeadersDropdown, getClassroomsBySite, updateScholarData, deleteFamily, getAddedScholarData, postScholarDataEnrollment } from "@/services/create-user/createUserService";
import APP_CONST from '@/constants/AppConst';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import UploadFile from './uploadFile/UploadFile.vue';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import { getSiteByProgramId } from "@/services/create-user/createUserService";

@Component({
    components: {
        'datepicker': DatepickerComponent,
        'upload-file': UploadFile,
        'progressButton':ProgressButton
    }
})
export default class RoasterPanel extends Vue {
    private  objScreenText: ScreenText = new ScreenText();
    private  scholarPanels:any = JSON.parse(JSON.stringify(APP_CONST.PANEL_ACCORDIONS_SCHOLAR));
    public   editScholarPanels:any = {};
    private  userRoles:any = [];
    private  customField:any = [];
    private allFilesArray:any=[]
    private  region:string =  ''
    public   flag : boolean = false;
    public   allFieldsRequired : boolean = false;
    public onSubmit: boolean = false;
    public invalidEmail: boolean = false;
    public postScholarData: boolean = false;
    public firstNameErrorText: string = '';
    public lastNameErrorText: string = '';
    private cities: string[] = APP_UTILITIES.fetchCities(APP_CONST.COUNTRY_NAME);
    private states: string[] = APP_UTILITIES.fetchStates(APP_CONST.COUNTRY_NAME);
    public invalidCity :boolean =false
    public invalidZipCode :boolean =false
    private hover:boolean = false;
    private hoverText:string = '';
    private hoverVal: string = '';
    private hoverStyleObj:any = {};
    public deleteDocument : boolean = false;
    public currentID : number =0;
    private mobileView:boolean = false;
    private errorOnPostScholar:string = "";
    public isMobileForAPiCall :boolean = false;
    public  validatedFields = APP_CONST.VALIDATE_CREATE_USER_FORM && JSON.parse(JSON.stringify(APP_CONST.VALIDATE_CREATE_USER_FORM));
    public firstNameError : boolean = false;
    public lastNameError : boolean = false;
    public firstNameErrorMessage : string = '';
    public lastNameErrorMessage : string = '';
    public submitInProgress: boolean = false;
    public apiResponseCode: number = 0;
    public isValidate: boolean= true;
    private inactiveDateError:boolean = false;
    private birthDateFormatErr:boolean = false;
    private birthDateErrorType:string = "";
    public weekDaysArray: any = APP_CONST.WEEK_DAYS;
    public scheduleDays: string = '';
    public updateScheduleDaysOfScholar: any = '';
    public  enableSiteScheduleDaysOfScholar : any = [];
    public  staffRoleId : number = 0;
    public  roleId : number = APP_CONST.SEVEN;

    @Prop()
    panelTitle!:any

    @Prop()
    family!:any

    @Prop()
    emergency!:any

    @Prop()
    createFormStatus!:boolean
    @Prop()
    rowItemsRoaster!:any
    
    @Prop()
    componentName!:string

    @Watch('panelTitle',{deep:true})
    callScheduleDays(){
        if(this.panelTitle !== 'Edit'){
            this.getSelectedScheduleDays('');
        }
    }

    @Watch('createFormStatus',{deep:true})
    checkUpdateStatus(val:any,oldVal:any){
     this.onSubmit = false;
     this.invalidEmail = false;
     this.postScholarData = false;
     this.firstNameErrorText = '';
     this.lastNameErrorText = '';
     this.inactiveDateError = false;
     this.allFieldsRequired = false;
     this.errorOnPostScholar = '';
     this.birthDateFormatErr = false;
     this.birthDateErrorType = "";
     this.filterCustomField("callingFromAddScholer")
     this.deleteFile('','clearAll')

   
     if(val==true){

        this.scholarPanels = JSON.parse(JSON.stringify(APP_CONST.PANEL_ACCORDIONS_SCHOLAR));
        if(this.componentName!=='step5'){
        this.fillDropdownValues();
        }
      
     }
    }

    @Prop()
    parametersStore!:any
    
    @Prop()
    rowIds!:any;

    @Prop()
    editScholarData!:any

    @Prop()
    rowToEdit!:any;

    created(){
        this.isMobileForAPiCall=APP_UTILITIES.mobileAndTabletCheck()
    }

    fillArrayIds(type:string,index:number,panelIndex:number){
        if(this.scholarPanels[panelIndex][type][index]){
            this.scholarPanels[panelIndex][type][index]['id'] = this.rowIds[type][index]['id'];
            this.scholarPanels[panelIndex][type][index]['scholarProgramSiteMappingId'] = this.rowIds[type][index]['scholarProgramSiteMappingId'];
        }
    }

    
    filterCustomField(type:string){
        this.customField=[] 
        if(this.panelTitle=='Edit'){
            Object.keys(this.rowToEdit).forEach((itemKey:any) => {
                if(this.rowToEdit[itemKey].columnType[0]==="Custom Fields"){
               this.addCustomFieldToArray(itemKey,this.rowToEdit[itemKey].value)
               
                }
                if(itemKey == 'birthDate' && this.rowToEdit[itemKey].value.length > 0){
                    this.rowToEdit[itemKey].value = APP_UTILITIES.getDateWithTextFieldFormat(this.rowToEdit[itemKey].value)
                }
                
            })
           
        
    }else{
        this.rowItemsRoaster.forEach((item:any) => {
            Object.keys(item).forEach((itemKey:any) => {
                if(item[itemKey].columnType[0]==="Custom Fields"){
               this.addCustomFieldToArray(itemKey,"")
               
                }
            })
        })  
        }
       
    }

    addCustomFieldToArray(itemKey:string,value:string){
        if(this.customField.length>0){
            let checkKey=this.customField.some(function(obj:any){
                return obj.name==itemKey
            })
            if(!checkKey){
                this.customField.push(
                    {
                      name:itemKey,
                      value: value,
                      errorStatus: false,
                      errorText:''
                  }
              
              )
            }
        }else{
            this.customField.push(
                  {
                    name:itemKey,
                    value: value,
                    errorStatus: false,
                    errorText:''
                }
            
            )
        }
        
    }

   
    @Watch('rowToEdit', {deep: true})
    handleEditableRow(valNew:any,oldVal:any){
      this.onSubmit = false;
      this.invalidEmail = false;
      this.postScholarData = false;
      this.allFieldsRequired = false;
      this.inactiveDateError = false;
      this.errorOnPostScholar = '';
      this.firstNameErrorText = '';
      this.lastNameErrorText = '';
      this.birthDateFormatErr = false;
      this.birthDateErrorType = "";
      this.updateScheduleDaysOfScholar = [{
        id:0,
        name:'Sunday',
        check : false,
        value: 'Su',
        enableDay: ''
    },
    { 
        id:1,
        name:'Monday',
        check : false,
        value: 'M',
        enableDay: ''
    },
    {   id:2,
        name:'Tuesday',
        check : false,
        value: 'T',
        enableDay: ''
    },
    {   id:3,
        name:'Wednesday',
        check : false,
        value: 'W',
        enableDay: ''
    },
    {   id:4,
        name:'Thursday',
        check : false,
        value: 'Th',
        enableDay: ''
    },
    {   id:5,
        name:'Friday',
        check : false,
        value: 'F',
        enableDay: ''
    },
    {   id:6,
        name:'Saturday',
        check : false,
        value: 'S',
        enableDay: ''
    }];
      let val:any = JSON.parse(JSON.stringify(valNew));
      this.getSelectedScheduleDays(valNew.scheduledDays.value);
      let scholarKeys:any = ['classroomName','scholarStudentId','addressLine1','addressLine2','postalCode','asthmaInhaler','specialEducation','scholarId','bellxcelId', 'scholarInformation', 'fieldTrip', 'madical', 'emergency', 'photoVideoOpt'];
      let keysToCheck:any= [{classroomName:'classroom'},{scholarStudentId:'studentSchoolIds'},{addressLine1:'address1'},{addressLine2:'address2'},{postalCode:'zip'},{asthmaInhaler:'inhaler'},{specialEducation:'specialEducationStatus'},{scholarId:'scholarIds'},{bellxcelId:'bellXcelId'}, {scholarInformation: 'scholarInfoCF'}, {fieldTrip: 'fieldTripCF'}, {madical: 'medicalCF'}, {emergency: 'emergencyCF'}, {photoVideoOpt: 'photoVideoOptOut'}];
      if(valNew!==oldVal){
         if(valNew.documents.value.length)
         {
            this.setFileData(valNew.documents.value)
         }
        this.filterCustomField("CallingfromEdit")
        this.$emit('oldStatus',valNew.status.value);
        this.scholarPanels.forEach((item:any) => {
          
            Object.keys(item).forEach((itemKey:any) => {
                if(typeof(item[itemKey]) == 'object' && !Array.isArray(item[itemKey])){
                  if(val.hasOwnProperty(itemKey)){
                      item[itemKey].value = val[itemKey].value;
                  }
                  else if(scholarKeys.indexOf(itemKey)!==-1){
                       let indexGot = scholarKeys.indexOf(itemKey);
                       item[itemKey].value = val[keysToCheck[indexGot][itemKey]]&&val[keysToCheck[indexGot][itemKey]].value;
                  }
                }
                else if (Array.isArray(item[itemKey])) {

                    if (itemKey == 'families') {
                        for (let i = 0; i < this.family.length; i++) {

                            if (i >= 1) {
                                item[itemKey].push({
                                    openStatus: true,
                                    lastName: {
                                        name: 'Last Name',
                                        value: '',
                                        errorStatus: false,
                                        errorText: 'Last Name is required'
                                    },
                                    firstName: {
                                        name: 'First Name',
                                        value: '',
                                        errorStatus: false,
                                        errorText: 'First Name is required'
                                    },
                                    email: {
                                        name: 'Email',
                                        value: '',
                                        errorStatus: false,
                                        errorText: ''
                                    },
                                    allowedToPick: {
                                        name: 'Authorized Pickup',
                                        value: '',
                                        dropStatus: false,
                                        listValues: ['Yes', 'No'],
                                        errorStatus: false,
                                        errorText: ''
                                    },
                                    relationship: {
                                        name: 'Relationship',
                                        value: '',
                                        errorStatus: false,
                                        errorText: ''
                                    },
                                    phoneNumber: {
                                        name: 'Phone Number',
                                        value: '',
                                        errorStatus: false,
                                        errorText: ''
                                    }
                                });
                            }


                            item[itemKey][i]['firstName'].value = this.family[i].firstName;
                            item[itemKey][i]['lastName'].value = this.family[i].lastName;
                            item[itemKey][i]['allowedToPick'].value = this.family[i].allowedToPickup;
                            item[itemKey][i]['email'].value = this.family[i].email;
                            item[itemKey][i]['phoneNumber'].value = this.family[i].phone;
                            item[itemKey][i]['relationship'].value = this.family[i].relationship;
                            this.fillArrayIds('families',i,APP_CONST.FIVE);
                        }

                    }
                    else if (itemKey == 'emergencyContacts') {
                        for (let i = 0; i < this.emergency.length; i++) {
                            if (i >= 1) {
                                item[itemKey].push({
                                    openStatus: true,
                                    lastName: {
                                        name: 'Last Name',
                                        value: '',
                                        errorStatus: false,
                                        errorText: 'Last Name is required'
                                    },
                                    firstName: {
                                        name: 'First Name',
                                        value: '',
                                        errorStatus: false,
                                        errorText: 'First Name is required'
                                    },
                                    email: {
                                        name: 'Email',
                                        value: '',
                                        errorStatus: false,
                                        errorText: ''
                                    },
                                    allowedToPick: {
                                        name: 'Authorized Pickup',
                                        value: '',
                                        dropStatus: false,
                                        listValues: ['Yes', 'No'],
                                        errorStatus: false,
                                        errorText: ''
                                    },
                                    relationship: {
                                        name: 'Relationship',
                                        value: '',
                                        errorStatus: false,
                                        errorText: ''
                                    },
                                    phoneNumber: {
                                        name: 'Phone Number',
                                        value: '',
                                        errorStatus: false,
                                        errorText: ''
                                    }
                                })
                            }
                            item[itemKey][i]['firstName'].value = this.emergency[i].firstName;
                            item[itemKey][i]['lastName'].value = this.emergency[i].lastName;
                            item[itemKey][i]['allowedToPick'].value = this.emergency[i].allowedToPickup;
                            item[itemKey][i]['email'].value = this.emergency[i].email;
                            item[itemKey][i]['phoneNumber'].value = this.emergency[i].phone;
                            item[itemKey][i]['relationship'].value = this.emergency[i].relationship;
                            this.fillArrayIds('emergencyContacts',i,APP_CONST.SIX);
                        }
                    }
                }
            })
        })
    }
    }

    

    
    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }
    openTile(tileIndex:number, $event: any){
        this.scholarPanels.forEach((element:any,elementIndex:number) => {
            if(elementIndex!==tileIndex){
                this.scholarPanels[elementIndex].openStatus = false;
            }
            else{
                if(this.scholarPanels[tileIndex].openStatus && $event.target.tagName !== "SPAN" && $event.target.tagName !== "svg" && $event.target.tagName !== "polyline"){
                    return;
                } 
                this.scholarPanels[tileIndex].openStatus = !this.scholarPanels[tileIndex].openStatus;
            }
        });
    }

  
    openInsideTile(tileIndex:number,key:string,arrayIndex:number, $event:any){
        if((this.scholarPanels[tileIndex][key][arrayIndex].openStatus && $event.target.tagName !== "SPAN" && $event.target.tagName !== "svg" && $event.target.tagName !== "polyline") || (this.scholarPanels[tileIndex][key][arrayIndex].openStatus && $event.target.tagName === "SPAN" && $event.target.className === "data-container")){
             return;
        }
        this.scholarPanels[tileIndex][key][arrayIndex].openStatus = !this.scholarPanels[tileIndex][key][arrayIndex].openStatus;
    }

    openDropdown(indexPanel:any,key:any,arrayIndex:number=0 , close ? :boolean){
        
        if(key!=='emergencyContacts' && key!=='families'){
            this.scholarPanels[indexPanel][key].dropStatus = this.scholarPanels[indexPanel][key].dropStatus ? APP_CONST.FALSE : (close ? APP_CONST.FALSE : APP_CONST.TRUE);
            let dropdownsToClose:any = [{value:'gender',index:0},{value:'ethnicity',index:0},{value:'classroomName',index:0},{value:'epiPen',index:7},{value:'asthmaInhaler',index:7},{value:'iepStatus',index:8},{value:'ellStatus',index:8},{value:'specialEducation',index:8}];
            dropdownsToClose.forEach((item:any) =>{
                  if(item.value!==key){
                    this.scholarPanels[item.index][item.value].dropStatus = false;
                  }
            })
        }
        else{
            this.scholarPanels[indexPanel][key][arrayIndex].allowedToPick.dropStatus = !this.scholarPanels[indexPanel][key][arrayIndex].allowedToPick.dropStatus;
            this.scholarPanels[indexPanel][key].forEach((item:any,itemIndex:number) => {
                if(itemIndex!==arrayIndex){
                    item.allowedToPick.dropStatus = false;
                }
            })
        }
        
    }

    deletePanel(panelIndex:number,key:string,familyIndex:any){
            if(this.scholarPanels[panelIndex][key][familyIndex].scholarProgramSiteMappingId||this.scholarPanels[panelIndex][key][familyIndex].scholarProgramSiteMappingId==0){
                let splicerIndex:any = this.rowIds[key].findIndex((item:any) => item.scholarProgramSiteMappingId ==this.scholarPanels[panelIndex][key][familyIndex].scholarProgramSiteMappingId);
                if(splicerIndex!==-1){
                    let deleteObj:any = {
                        id:this.rowIds[key][splicerIndex].id,
                        scholarprogramsitemapping:this.rowIds[key][splicerIndex].scholarProgramSiteMappingId
                    }
                    if(key=='families' || key=='emergencyContacts' ){
                        deleteFamily(deleteObj).then((response:any) => {
                            if(response.status ==200){
                                this.rowIds[key].splice(splicerIndex,APP_CONST.ONE);
                                let objToSend:any = {
                                    rowIds:this.rowIds,
                                    key:key,
                                    spliceId:splicerIndex
                                }
                                this.$emit('rowIdUpdater',objToSend);
                                this.scholarPanels[panelIndex][key].splice(familyIndex,APP_CONST.ONE);
                                if(this.scholarPanels[panelIndex][key].length <1){
                                    this.addNewFormPanel(panelIndex,key);
                               }
                            }
                        }).catch((err:any) => {
    
                        })
                    }
                   
                }
            }
            else{
                this.scholarPanels[panelIndex][key].splice(familyIndex,APP_CONST.ONE);
            }
       if(this.scholarPanels[panelIndex][key].length <1){
            this.addNewFormPanel(panelIndex,key);
       }
    }

    addNewFormPanel(panelIndex:any,key:any){
        let panelObj:any = JSON.parse(JSON.stringify({...APP_CONST.LINK_OBJ}));
        this.scholarPanels[panelIndex][key].forEach((item:any) => {
            item.openStatus = false;
        })
        if(this.scholarPanels[panelIndex][key].length<=9){
            this.scholarPanels[panelIndex][key].push(panelObj);
        }
    }

    selectList(indexPanel:any,key:any,listValue:any,arrayIndex:number=0){
        if(key!=='emergencyContacts' && key!=='families'){
        this.scholarPanels[indexPanel][key].value = listValue;
        this.scholarPanels[indexPanel][key].errorStatus = APP_CONST.FALSE;
        this.scholarPanels[indexPanel][key].dropStatus = !this.scholarPanels[indexPanel][key].dropStatus;
        }
        else{
            this.scholarPanels[indexPanel][key][arrayIndex].allowedToPick.value =listValue;
            this.scholarPanels[indexPanel][key][arrayIndex].allowedToPick.dropStatus =false;
        }
    }

    closeDropdown(indexPanel:any,key:any,arrayIndex:number=0){
        if(key=='emergencyContacts' && key=='families'){
            this.scholarPanels[indexPanel][key][arrayIndex].allowedToPick.dropStatus = !this.scholarPanels[indexPanel][key][arrayIndex].allowedToPick.dropStatus;
        }
        else{
            this.scholarPanels[indexPanel][key].dropStatus =false;
        }
    }
    
   
    postScholar(){
        if(this.submitInProgress){
            return;
        }
        let requiredFields:any = ['lastName','firstName','enteringGrade','birthDate'];
        let otherFieldsCheck:any = [''];
        this.firstNameErrorText = '';
        this.lastNameErrorText = '';
        let errorCheck:any = false;
        this.invalidCity = false;
        this.invalidZipCode = false
        this.onSubmit = false;
        this.allFieldsRequired = false;
        this.inactiveDateError = false;
        this.errorOnPostScholar = "";
        this.invalidEmail = false;
        this.birthDateFormatErr = false;
        this.scholarPanels.forEach((item:any) => {
            if(requiredFields.some((key:any) => item.hasOwnProperty(key))){
                Object.keys(item).forEach((itemKey:any) => {
                  
                    if(item.hasOwnProperty(itemKey)&&requiredFields.indexOf(itemKey)!==-1){
                        if(itemKey!=='enteringGrade' && itemKey!== 'birthDate'&&item[itemKey].value ==''){
                            item[itemKey].errorStatus = true;
                            this.allFieldsRequired = true
                            errorCheck = true;
                        }
                        if((itemKey== 'birthDate' && item[itemKey].value.length < 14)){
                            item[itemKey].errorStatus = true;
                            this.allFieldsRequired = true
                            errorCheck = true;
                        }
                        else if(itemKey=='enteringGrade'&&item[itemKey].value =='Select'){
                            item[itemKey].errorStatus = true;
                            this.allFieldsRequired = true
                            errorCheck = true;
                        }
                        else{
                            item[itemKey].errorStatus &&(item[itemKey].errorStatus = false);
                        }
                    }
                    else{
                        item[itemKey]&&item[itemKey].errorStatus&& (item[itemKey].errorStatus= false);
                    }

                })
            }
            else if(otherFieldsCheck.some((key:any) => item.hasOwnProperty(key))){
                if(item.hasOwnProperty(otherFieldsCheck[APP_CONST.ZERO])){
                item[otherFieldsCheck[APP_CONST.ZERO]].forEach((contact:any) => {
                       
                       if(requiredFields.some((key:any) => contact.hasOwnProperty(key))){
                        Object.keys(contact).forEach((itemKey:any) => {
                            if(contact.hasOwnProperty(itemKey) && requiredFields.indexOf(itemKey)!==-1){
                               
                                if(itemKey!=='enteringGrade'&&contact[itemKey].value ==''){
                                    contact[itemKey].errorStatus = true;
                                    this.allFieldsRequired = true
                                    errorCheck = true;
                                }
                                else if(itemKey=='enteringGrade'&&contact[itemKey].value =='Select'){
                                    contact[itemKey].errorStatus = true;
                                    this.allFieldsRequired = true
                                    errorCheck = true;
                                }
                                else{
                                    contact[itemKey].errorStatus&&(contact[itemKey].errorStatus = false);
                                }
                            }
                            else{
                                contact[itemKey]&&contact[itemKey].errorStatus&& (contact[itemKey].errorStatus= false);
                            }
        
                        })
                    }
                })
            }
            if(item.hasOwnProperty(otherFieldsCheck[APP_CONST.ONE])){
                item[otherFieldsCheck[APP_CONST.ONE]].forEach((contact:any) => {
                      
                       if(requiredFields.some((key:any) => contact.hasOwnProperty(key))){
                        Object.keys(contact).forEach((itemKey:any) => {
                            if(contact.hasOwnProperty(itemKey) && requiredFields.indexOf(itemKey)!==-1){
                                if(itemKey!=='enteringGrade'&&contact[itemKey].value ==''){
                                    contact[itemKey].errorStatus = true;
                                    this.allFieldsRequired = true
                                    errorCheck = true;
                                }
                                else if(itemKey=='enteringGrade'&&contact[itemKey].value =='Select'){
                                    contact[itemKey].errorStatus = true;
                                    this.allFieldsRequired = true
                                    errorCheck = true;
                                }
                                else{
                                    contact[itemKey]&& contact[itemKey].errorStatus&&(contact[itemKey].errorStatus= false); 
                                }
                            }
                            else{
                                contact[itemKey]&&contact[itemKey].errorStatus&& (contact[itemKey].errorStatus= false);
                            }
        
                        })
                    }
                })
            }
        }
        })
            let familiesArray:any = [];
            let emergencyArray:any = [];
            this.scholarPanels[APP_CONST.FIVE].families.forEach((item:any,index:number) => {
                let familObj:any = {
                    "id":this.panelTitle!=='Edit'?0:this.rowIds.families[index] && this.rowIds.families[index].id?this.rowIds.families[index].id:0,
                    "scholarProgramSiteMappingId":this.panelTitle!=='Edit'?0:this.rowIds.families[index] && this.rowIds.families[index].scholarProgramSiteMappingId?this.rowIds.families[index].scholarProgramSiteMappingId:0,
                    "firstName": item.firstName.value,
                    "lastName": item.lastName.value,
                    "phone":   item.phoneNumber.value.length>0?  JSON.stringify(parseInt(APP_UTILITIES.removeSpecialCharacters(item.phoneNumber.value))) :'' ,
                    "phoneOther": "",
                    "isEmergencyContact": false,
                  
                    "allowedToPickup": item.allowedToPick.value,                  
                    "relationship": item.relationship.value,
                    "email": item.email.value,
                    "status": this.panelTitle!=='Edit'?0:true,
                  
                  }
                  if(item.phoneNumber.value != "" || (item.firstName.value !="" && item.lastName.value != "")){
                    familiesArray.push(familObj);
                  }
            })
            this.scholarPanels[APP_CONST.SIX].emergencyContacts.forEach((item:any,index:number) => {
                let emergencyObj:any = {
                    "id":this.panelTitle!=='Edit'?0:this.rowIds.emergencyContacts[index] && this.rowIds.emergencyContacts[index].id?this.rowIds.emergencyContacts[index].id:0,
                    "scholarProgramSiteMappingId": this.panelTitle!=='Edit'?0: this.rowIds.emergencyContacts[index] && this.rowIds.emergencyContacts[index].scholarProgramSiteMappingId?this.rowIds.emergencyContacts[index].scholarProgramSiteMappingId:0, 
                    "firstName":  item.firstName.value,
                    "lastName": item.lastName.value,
                    "phone": item.phoneNumber.value.length>0?  JSON.stringify(parseInt(APP_UTILITIES.removeSpecialCharacters(item.phoneNumber.value))) :'',
                    "phoneOther": "",
                    "isEmergencyContact": true,
                 
                    "allowedToPickup": item.allowedToPick.value,    
                    "relation": item.relationship.value,
                    "email": item.email.value,
                    "status": this.panelTitle!=='Edit'?0:true,
                 
                  }
                  if(item.phoneNumber.value != "" || (item.firstName.value !="" && item.lastName.value != "")){
                    emergencyArray.push(emergencyObj);
                  }
            })
            let val =  ((this.scholarPanels[APP_CONST.FOUR].city.value).trimLeft()).length > 0  ? this.scholarPanels[APP_CONST.FOUR].city.value : '';

            let scholarData:any = {
                "accountId": this.userRoles[APP_CONST.ZERO].accountId,
                "id": this.panelTitle!=='Edit'?0:this.rowIds.id,
                "programId":this.userRoles[APP_CONST.ZERO].programId,
                "siteId": this.userRoles[APP_CONST.ZERO].siteId,
                "uid": this.panelTitle!=='Edit'?'':this.scholarPanels[APP_CONST.TWO].bellxcelId.value,
                "schoolStudentId": this.scholarPanels[APP_CONST.TWO].scholarStudentId.value,
                "externalScholarId": this.scholarPanels[APP_CONST.TWO].scholarId.value,
                "firstName": this.scholarPanels[APP_CONST.ZERO].firstName.value,
                "lastName": this.scholarPanels[APP_CONST.ZERO].lastName.value,
                "documents":this.getAllId(),
                "age": 2 ,
                "gender": this.scholarPanels[APP_CONST.ZERO].gender.value=='Select' ?'':this.scholarPanels[APP_CONST.ZERO].gender.value=='Others'?this.scholarPanels[APP_CONST.ZERO].otherGender.value:this.scholarPanels[APP_CONST.ZERO].gender.value,
                "raceEthnicity": this.scholarPanels[APP_CONST.ZERO].ethnicity.value =='Select'?'': this.scholarPanels[APP_CONST.ZERO].ethnicity.value,
                "grade": this.scholarPanels[APP_CONST.ZERO].enteringGrade.value =='Select'?'':this.scholarPanels[APP_CONST.ZERO].enteringGrade.value,
                "classroom":  this.scholarPanels[APP_CONST.ZERO].classroomName.value =='Select'?'':this.scholarPanels[APP_CONST.ZERO].classroomName.value.value? this.scholarPanels[APP_CONST.ZERO].classroomName.value.value:this.scholarPanels[APP_CONST.ZERO].classroomName.value,
                "birthDate": JSON.stringify(this.scholarPanels[APP_CONST.ZERO].birthDate.value).replace(/['"]+/g, ''),
                "scheduledDays": this.scheduleDays,
                "phone": "",
                "enrollmentDate": JSON.stringify(this.scholarPanels[APP_CONST.THREE].enrollmentDate.value).replace(/['"]+/g, ''),
                "inactiveDate": (this.scholarPanels[APP_CONST.THREE].status.value =='Inactive' && this.scholarPanels[APP_CONST.THREE].inactiveDate.value !== '' ) ?  APP_UTILITIES.getFullDate(JSON.stringify(this.scholarPanels[APP_CONST.THREE].inactiveDate.value).replace(/['"]+/g, '')) : '',
                "inactiveNotes": this.scholarPanels[APP_CONST.THREE].status.value =='Inactive' ? this.scholarPanels[APP_CONST.THREE].inactiveNotes.value : '',
                "status": (this.scholarPanels[APP_CONST.THREE].status.value =='Select'||this.scholarPanels[APP_CONST.THREE].status.value =='Inactive')?2:1,
              
                "guardians": familiesArray,
                "emergencyContacts": emergencyArray,
                "address": {
                  "id": this.panelTitle!=='Edit'?0:this.rowIds.address.id,
                  "scholarProgramSiteMappingId":  this.panelTitle!=='Edit'?0:this.rowIds.address.scholarProgramSiteMappingId,
                  "address1":this.scholarPanels[APP_CONST.FOUR].addressLine1.value,
                  "address2": this.scholarPanels[APP_CONST.FOUR].addressLine2.value,
                  "state": this.scholarPanels[APP_CONST.FOUR].state.value,
                  "city": ((this.scholarPanels[APP_CONST.FOUR].city.value).trimLeft()).length > 0  ? this.scholarPanels[APP_CONST.FOUR].city.value : '', 
                  "zip": this.scholarPanels[APP_CONST.FOUR].postalCode.value==''?'':this.scholarPanels[APP_CONST.FOUR].postalCode.value,
            
                },
                "medical": {
                  "id": this.panelTitle!=='Edit'?0:this.rowIds.medical.id,
                  "scholarProgramSiteMappingId": this.panelTitle!=='Edit'?0:this.rowIds.medical.scholarProgramSiteMappingId,
                  "epiPen":this.scholarPanels[APP_CONST.SEVEN].epiPen.value=='Select'?'':this.scholarPanels[APP_CONST.SEVEN].epiPen.value,
                  "asthmaInhaler": this.scholarPanels[APP_CONST.SEVEN].asthmaInhaler.value =='Select'? '':this.scholarPanels[APP_CONST.SEVEN].asthmaInhaler.value,
                  "allergies": this.scholarPanels[APP_CONST.SEVEN].allergies.value,
                  "medication": this.scholarPanels[APP_CONST.SEVEN].medications.value,
                  "health": "",
                  "iepstatus": this.scholarPanels[APP_CONST.EIGHT].iepStatus.value =='Select'?'':this.scholarPanels[APP_CONST.EIGHT ].iepStatus.value,
                  "ellstatus":  this.scholarPanels[APP_CONST.EIGHT].ellStatus.value == 'Select'?'':this.scholarPanels[APP_CONST.EIGHT ].ellStatus.value,
                  "specialEducationStatus": this.scholarPanels[APP_CONST.EIGHT ].specialEducation.value =='Select'?'':this.scholarPanels[APP_CONST.EIGHT ].specialEducation.value,
              
                }
               
                // "consent": {
                //   "id": this.panelTitle!=='Edit'?0:this.rowIds.consent.id,
                //   "scholarProgramSiteMappingId": this.panelTitle!=='Edit'?0:this.rowIds.consent.scholarProgramSiteMappingId,
                //   "siteCustomHeaderId": null,
                //   "scholarInfoForm": this.scholarPanels[APP_CONST.TEN].scholarInformation.value,
                //   "fieldTripForm": this.scholarPanels[APP_CONST.TEN].fieldTrip.value,
                //   "medicalForm": this.scholarPanels[APP_CONST.TEN].madical.value,
                //   "emergencyForm": this.scholarPanels[APP_CONST.TEN].emergency.value,
                //   "photoOptOut": this.scholarPanels[APP_CONST.TEN].photoVideoOpt.value,
                //   "customValue1": this.customField.length>0?this.customField[0].value:"",
                //   "customValue2": this.customField.length>1?this.customField[1].value:"",
                //   "customValue3": this.customField.length>2?this.customField[2].value:"",
                //   "customValue4": this.customField.length>3?this.customField[3].value:"",
                //   "customValue5": this.customField.length>4?this.customField[4].value:"",
                //   "customValue6": this.customField.length>5?this.customField[5].value:"",
              
                // }
              }

            scholarData.enrollmentDate = scholarData.enrollmentDate!=='' ? APP_UTILITIES.getFullDate(scholarData.enrollmentDate) :'';
            scholarData.birthDate = scholarData.birthDate!=='' ? scholarData.birthDate.replace(/ - /g, "/") : "";
            this.postScholarData = true;
          
            for (let i = 0; i < this.scholarPanels.length; i++) {
                for (let scholar in this.scholarPanels[i]) {
                    if (scholar == 'families' || scholar == 'emergencyContacts') {
                        for (let j = 0; j < this.scholarPanels[i][scholar].length; j++) {
                            if (this.scholarPanels[i][scholar][j].phoneNumber && APP_UTILITIES.removeSpecialCharacters(this.scholarPanels[i][scholar][j].phoneNumber.value) && (APP_UTILITIES.removeSpecialCharacters(this.scholarPanels[i][scholar][j].phoneNumber.value)).length < 10) {
                                this.onSubmit = true;
                            }
                            if (this.scholarPanels[i][scholar][j].email && this.scholarPanels[i][scholar][j].email.value && this.ValidateEmail(this.scholarPanels[i][scholar][j].email.value)) {
                                this.invalidEmail = true;
                            }
                            if (this.scholarPanels[i][scholar][j].firstName && this.scholarPanels[i][scholar][j].firstName.value && this.scholarPanels[i][scholar][j].firstName.errorStatus || this.scholarPanels[i][scholar][j].lastName && this.scholarPanels[i][scholar][j].lastName.value && this.scholarPanels[i][scholar][j].lastName.errorStatus) {
                                this.onSubmit = true;
                            }
                        }
                    }
                    if(scholar == 'city')
                    {
                        let city = this.scholarPanels[i][scholar].value.trimLeft();
                        if (city && city.length > 0 && (!APP_UTILITIES.validateAlphaOnly(city))) {
                            this.onSubmit = true;
                            this.invalidCity = true;
                        }
                    }
                    if (scholar == 'postalCode') {
                        let zipCode = this.scholarPanels[i][scholar].value;
                        if (!APP_UTILITIES.validateNumber(zipCode)) {
                            this.onSubmit = true;
                            this.invalidZipCode = true;
                        }
                    }
                    if(scholar == 'lastName'){
                        if(this.scholarPanels[i][scholar].value.length > 0 && (!APP_UTILITIES.validateAlphaAndSpecialChar(this.scholarPanels[i][scholar].value))) {
                            this.lastNameErrorText = '';
                        }
                        else{
                            this.lastNameErrorText = this.getScreenText('PLEASE_USE_ALPHABETS_HYPHENS_AND_APOSTROPES');
                        }
                    }
                    if(scholar == 'firstName'){
                        if(this.scholarPanels[i][scholar].value.length > 0 && (!APP_UTILITIES.validateAlphaAndSpecialChar(this.scholarPanels[i][scholar].value))) {
                            this.firstNameErrorText = '';
                        }
                        else{
                            this.firstNameErrorText = this.getScreenText('PLEASE_USE_ALPHABETS_HYPHENS_AND_APOSTROPES');
                        }
                    }
                    if(scholar == 'status'){
                        if(this.scholarPanels[i][scholar].value == 'Inactive' && this.scholarPanels[i]['inactiveDate'].value == ''){
                            this.inactiveDateError = true;
                        }
                    }
                    if(scholar == 'birthDate'){
                        if(this.scholarPanels[i][scholar].value.length == 14) {
                            this.onblurdate(this.scholarPanels[i][scholar].value);
                        } else{
                            this.birthDateFormatErr = false;
                        }
                       
                    }
                }
            }
            
            if(this.onSubmit || this.invalidEmail || this.invalidCity || errorCheck || this.lastNameErrorText || this.firstNameErrorText || this.inactiveDateError || this.birthDateFormatErr || this.invalidZipCode){
                return;
            }
            if(this.panelTitle!=='Edit'){
                this.submitInProgress = true;
                this.isValidate=false;
                this.apiResponseCode=0;
                postScholarDataEnrollment(scholarData).then((reponse:any) => {
                    if(reponse.status === APP_CONST.RESPONSE_200){
                        this.submitInProgress = false;
                        this.apiResponseCode=APP_CONST.RESPONSE_200;
                        this.$emit('addedScholar',1,"move");
                        this.$emit('createUserForm',false, '' , true);
                    }
                    else if(reponse.status ===400){
                        this.submitInProgress = false;
                        this.apiResponseCode=APP_CONST.RESPONSE_400;
                        if(reponse.data && reponse.data.errors){
                            if(reponse.data.errors.firstName){
                                this.firstNameErrorText = reponse.data.errors.firstName[0]
                            }
                            if(reponse.data.errors.lastName){
                                this.lastNameErrorText = reponse.data.errors.lastName[0]
                            }
                        }

                        let concatestrings:string = '';
                        Object.keys(reponse.data.errors).forEach((err:any)=>{
                            
                         if(Array.isArray(reponse.data.errors[err])){
                            reponse.data.errors[err].forEach((insideErr:any) => {
                                concatestrings+=insideErr;
                            })
                         }
                        })
                    } else if(reponse.status === APP_CONST.RESPONSE_406){
                        this.submitInProgress = false;
                        this.apiResponseCode=APP_CONST.RESPONSE_400;
                        this.errorOnPostScholar = reponse.data;
                    }
                    
                }).catch((error:any) => {
                    this.submitInProgress = false;
                })
            }
            else if(this.panelTitle==='Edit'){
                this.isValidate=false;
                this.apiResponseCode=0;
              updateScholarData(scholarData).then((response:any) => {
                this.submitInProgress = true;
                if(response.status === APP_CONST.RESPONSE_200){
                    this.submitInProgress = false;
                    this.apiResponseCode=APP_CONST.RESPONSE_200;
                    let parametricObj:any = {
                        id:scholarData.id,
                        siteId:this.parametersStore.siteId,
                        programId:this.parametersStore.programId
                    }
                    getAddedScholarData(parametricObj).then((addedScholar:any) => {
                        if(addedScholar.status ==APP_CONST.RESPONSE_200){
                            addedScholar.data.enrollmentDate = scholarData.enrollmentDate!=='' ? APP_UTILITIES.getFullDate(scholarData.enrollmentDate) :'';
                            addedScholar.data.birthday = scholarData.birthday!=='' ? APP_UTILITIES.getFullDate(scholarData.birthday) :'';
                            addedScholar.data.inactiveDate = (scholarData.status !== 'Inactive' && scholarData.inactiveDate) ? APP_UTILITIES.getFullDate(scholarData.inactiveDate) :'';
                            addedScholar.data.documents=this.allFilesArray;
                            this.$emit('updateRow',addedScholar.data,this.customField);
                            this.$emit('createUserForm',false, false);
                            this.$emit('sortOnSubmit');
                        }
                      }).catch((err:any) => {
  
                    })
                }
                else if(response.status ===400){
                    this.submitInProgress = false;
                    this.apiResponseCode=APP_CONST.RESPONSE_400;
                    if(response.data && response.data.errors){
                        if(response.data.errors.firstName){
                            this.firstNameErrorText = response.data.errors.firstName[0]
                        }
                        if(response.data.errors.lastName){
                            this.lastNameErrorText = response.data.errors.lastName[0]
                        }
                    }
                }else if(response.status === APP_CONST.RESPONSE_406){
                        this.submitInProgress = false;
                        this.apiResponseCode=APP_CONST.RESPONSE_400;
                        this.errorOnPostScholar = response.data;
                }
            }).catch((err:any) => {
                this.submitInProgress = false;
            })
        }
        if(this.panelTitle !== 'Edit'){
            this.getSelectedScheduleDays('');
        }
    }
    showSuccessPopup(addedScholar:any){
            if(addedScholar && addedScholar.data){
                this.$emit('addedScholar',addedScholar.data,this.customField);
            }
    }

    closeForm(status:boolean){
        this.submitInProgress = false;
        this.apiResponseCode=0;
        this.isValidate=true;
        this.allFieldsRequired = false;
        this.onSubmit = false;
        this.invalidEmail = false; 
        this.invalidCity = false;
        this.errorOnPostScholar = '';
        this.inactiveDateError = false;
        document.body.style.pointerEvents ="all"
        this.$emit('createUserForm',status, false);
        this.validatedFields.lastName.alphaCharError=false;
        this.validatedFields.firstName.alphaCharError=false;
    }
    getClassroomsBySiteId(siteId:number){
        getClassroomsBySite(this.userRoles[APP_CONST.ZERO].programId,siteId).then((reponse:any) => {
            if(reponse.status === APP_CONST.RESPONSE_200){
                const data = reponse.data;
                if(data){
                    let classFill:any = [];
                    data.forEach((item:any) => {
                        classFill.push({value:item.classroomName ,id:item.id});
                    })
                   this.scholarPanels[APP_CONST.ZERO].classroomName.listValues = classFill;
                }
            }
        })
    }


    public checkPhoneFormat(event: any,panelIndex:any,key:any,objIndex:any) {
        
            if (event && APP_UTILITIES.isModifierKey(event)) { return; }
            let input: any = this.scholarPanels[panelIndex][key][objIndex].phoneNumber.value && this.scholarPanels[panelIndex][key][objIndex].phoneNumber.  value.replace(/\D/g, '').substring(0, 10);
            const zip = input && input.substring(0, 3);
            const middle = input && input.substring(3, 6);
            const last = input && input.substring(6, 10);
            this.scholarPanels[panelIndex][key][objIndex].phoneNumber.value = `(${zip}) ${middle}-${last}`;   
    };

    public enforceFormat(event: any,type:string) {
        if(type == 'phoneNumber'){
        if (!APP_UTILITIES.isNumericInput(event) && !APP_UTILITIES.isModifierKey(event)) {
            event.preventDefault();
        }
     }
    };
    
    onlyNumber ($event : any) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { 
           $event.preventDefault();
        }
     }

    fillDropdownValues(){
   getHeadersDropdown().then((response:any) => {
        if(response.status === APP_CONST.RESPONSE_200){
            const data = response.data;
            let dropdownsToClose:any = [{value:'gender',index:0,columnName:'Gender'},{value:'enteringGrade',index:0,columnName:'Entering Grade'},{value:'ethnicity',index:0,columnName:'Ethnicity'},{value:'epiPen',index:7,columnName:'Epi Pen'},{value:'asthmaInhaler',index:7,columnName:'Asthma Inhaler'},{value:'iepStatus',index:8,columnName:'IEP Status'},{value:'ellStatus',index:8,columnName:'ELL/LEP Status'},{value:'specialEducation',index:8,columnName:'Special Education Status'}];
            data.forEach((item:any) => {
                let indexFinder:any = dropdownsToClose.findIndex((dropObj:any) =>dropObj.columnName === item.columnName);
                if(indexFinder!==-1){
                    this.scholarPanels[dropdownsToClose[indexFinder].index][dropdownsToClose[indexFinder].value].listValues = item.options;
                }
               
            })
           
        }
    }).catch((error:any) => {

    })
    if(this.componentName!=='step5'){
   this.getClassroomsBySiteId(this.userRoles[APP_CONST.ZERO].siteId);
    }
    }

    ValidateEmail(mail: any) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (re.test(mail) ? false : true);   
           }

    beforeMount(){
        let userRoles: any = APP_UTILITIES.getCookie("user_role");
        this.userRoles = JSON.parse(userRoles);
        this.fillDropdownValues();
        this.onSubmit = false;
        this.invalidEmail = false;
        this.inactiveDateError = false;
        this.postScholarData = false;
        this.firstNameErrorText = '';
        this.lastNameErrorText = '';
        this.allFieldsRequired = false;
        this.errorOnPostScholar = '';
        this.birthDateFormatErr = false;
        this.birthDateErrorType = "";
        this.staffRoleId = this.userRoles[APP_CONST.ZERO].roleId;
    }

  isMobile(){
    if (document.documentElement.clientWidth < 769 && !this.mobileView) {
      this.mobileView = true;
    } else if(document.documentElement.clientWidth >= 769 && this.mobileView){
      this.mobileView = false;
    } 
  }

  mounted() {
    this.mobileView = document.documentElement.clientWidth < 769 ? true : false;
    window.addEventListener("resize", APP_UTILITIES.debounce(this.isMobile));
    this.getSelectedScheduleDays('');
  }
  destroyed() {
    window.removeEventListener("resize", this.isMobile);
  }

    checkForDate() {
        if(this.scholarPanels[APP_CONST.THREE].enrollmentDate.value!=''){
        if( this.scholarPanels[APP_CONST.THREE].enrollmentDate.value!=''&& new Date(this.scholarPanels[APP_CONST.THREE].inactiveDate.value)<new Date( this.scholarPanels[APP_CONST.THREE].enrollmentDate.value)){
            this.scholarPanels[APP_CONST.THREE].inactiveDate.value=''
           return this.scholarPanels[APP_CONST.THREE].enrollmentDate.value
        }
         if(this.scholarPanels[APP_CONST.THREE].inactiveDate.value=='' || new Date(this.scholarPanels[APP_CONST.THREE].inactiveDate.value)>=new Date( this.scholarPanels[APP_CONST.THREE].enrollmentDate.value)){
            return this.scholarPanels[APP_CONST.THREE].enrollmentDate.value
        }
    }else{
            const d = new Date();
            d.setDate(d.getDate());
            return d;
        }
       
    }

    getPhoneNumber(value : string)
    {
        let values=  APP_UTILITIES.removeSpecialCharacters(value)
        return values
    }

     openstateDropdown(indexPanel:any,key:any , close ? :boolean) {
        this.scholarPanels[indexPanel][key].dropStatus = this.scholarPanels[indexPanel][key].dropStatus ? APP_CONST.FALSE : (close ? APP_CONST.FALSE : APP_CONST.TRUE);
    }

    selectOption(indexPanel:any,key:any,listValue:any)
    {
        this.scholarPanels[indexPanel][key].value = listValue;
        this.scholarPanels[indexPanel][key].dropStatus = !this.scholarPanels[indexPanel][key].dropStatus;       
    }

   
    callHover(val : string, $event: any){
        this.hover = true;
        this.hoverText = val;
        this.hoverVal = $event.currentTarget.innerText;
        const boundBox = $event.target.getBoundingClientRect();
        const coordX = boundBox.left;
        const coordY = boundBox.top;
        this.hoverStyleObj = {
            top:(coordY + 25).toString() + "px",
            left:(coordX + 60).toString() + "px"
        }
        
    }
    
   
    callHoverOut(val : string){
        this.hover = false;
        this.hoverText = val;
        this.hoverVal = "";
        this.hoverStyleObj= {};
    }
    accordion(panel:any,accordionType:any,errorText:any){
        if(accordionType=='Scholar Info'){
        if(this.firstNameErrorText || panel.lastName.errorStatus && panel.lastName.value.length === 0){
            return true
        }
        if(this.lastNameErrorText || panel.firstName.errorStatus && panel.firstName.value.length === 0){
            return true
        }
         if(panel.enteringGrade.errorStatus && panel.enteringGrade.value.length === 0) {
            return true
         }
         if(this.birthDateFormatErr || (panel.birthDate.errorStatus && panel.birthDate.value.length < 14)){
            return true
        }
        if(errorText.length>0){
            return true
        }
        return false
        }
        if(accordionType=='ScheduleDays'){
            if(errorText){
                return true
            }
            return false
        }
        if(accordionType=='Address'){
           if(errorText){
               return true
           } 
           if((panel.postalCode.value.length>0 && panel.postalCode.value.length!=5) || this.invalidZipCode ){
            return true
           }
             return false
        }
      
        if(accordionType=='Family'){
            for(var i=0;i<panel.families.length;i++){
               if(panel.families[i].email.value && this.invalidEmail && this.ValidateEmail(panel.families[i].email.value )){
                return true
               
               }
               if(panel.families[i].phoneNumber.value && this.getPhoneNumber(panel.families[i].phoneNumber.value).length<10) {
                   return true
               }
               if(panel.families[i].firstName.value && panel.families[i].firstName.errorStatus || panel.families[i].lastName.value && panel.families[i].lastName.errorStatus ){
                return true
               }
            }
            return false;
        }
        if(accordionType=='FamilyChild'){
               if(panel.email.value && this.invalidEmail && this.ValidateEmail(panel.email.value )){
                return true
               
               }
               if(panel.phoneNumber.value && this.getPhoneNumber(panel.phoneNumber.value).length<10) {
                   return true
               }
            return false;
        }
        if(accordionType=='Emergency Contact'){
            for(var j=0;j<panel.emergencyContacts.length;j++){
               if(panel.emergencyContacts[j].email.value && this.invalidEmail && this.ValidateEmail(panel.emergencyContacts[j].email.value )){
                return true
               }
               if(panel.emergencyContacts[j].phoneNumber.value && this.getPhoneNumber(panel.emergencyContacts[j].phoneNumber.value).length<10) {
                   return true
               }
               if(panel.emergencyContacts[j].firstName.value && panel.emergencyContacts[j].firstName.errorStatus || panel.emergencyContacts[j].lastName.value && panel.emergencyContacts[j].lastName.errorStatus ){
                return true
               }
            }
            return false;
        }
        if(accordionType=='EmergencyChildContact'){
               if(panel.email.value && this.invalidEmail && this.ValidateEmail(panel.email.value )){
                return true
               }
               if(panel.phoneNumber.value && this.getPhoneNumber(panel.phoneNumber.value).length<10) {
                   return true
               }
            return false;
        }
        if(accordionType == 'inactiveDate'){
            if(this.inactiveDateError){
                return true
            }
            return false;
        }
    }

    private closeTooltip() {
        const toolTipReference: any = document.getElementById(APP_CONST.TOOL_TIP);
        toolTipReference.style.visibility = APP_CONST.HIDDEN;
    }

    private openTooltip() {
        const toolTipReference: any = document.getElementById(APP_CONST.TOOL_TIP);
        if (!toolTipReference.style.visibility || toolTipReference.style.visibility === APP_CONST.HIDDEN) {
            toolTipReference.style.visibility = APP_CONST.VISIBLE;
        }
    }

   openFilePopUp(){
    this.$emit('openDuplicateFilePopUp');
   } 
   getAllId(){
    let docId:any=[]
    this.allFilesArray.map((obj:any)=>{
        docId.push({id:obj.id})
    })
    return docId
   }
   fileToView(fileArray:any){
    this.allFilesArray=fileArray
   }
  
   replaceFile(){
    let csvInstance: any = this.$refs.uploadFileComponent;
    csvInstance[0].replaceFileHandler();
   }
   bothFile(){
    let csvInstance: any = this.$refs.uploadFileComponent;
    csvInstance[0].bothFileHandler();
   }
   
   deleteFile(fileObj:any,type:any){
    if(type=="clearAll"){
        this.allFilesArray=[]
    }else{
    let csvInstance: any = this.$refs.uploadFileComponent;
    csvInstance[0].deleteFileHandler(fileObj);
    }
    this.cancelDeleteFile()
   }
     showConfirmation(file:any, index: number) {        
        this.deleteDocument = true;
        this.currentID = file.id
        setTimeout(async ()=>{
       if(document.getElementById(file.id)!=null){
        const visible =  await this.isVisible(document.getElementById(file.id));
        if(!visible){
        let popUp:any=document.getElementById(file.id)
        popUp.scrollIntoView(true)
        }
       }
    },10)
    }
     isVisible(domElement:any) {
        return new Promise(resolve => {
          const o = new IntersectionObserver(([entry]) => {
            resolve(entry.intersectionRatio === 1);
            o.disconnect();
          });
          o.observe(domElement);
        });
      }

    cancelDeleteFile()
    {
        this.deleteDocument = false;
        this.currentID = 0
    }

    getFileNameAndtype(name: any) {
        const lastDot = name.lastIndexOf('.');
        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);
        return { fileName: fileName, ext: ext }
    }

    setFileData(data: any)
    {
                 for(let i=0;i<data.length;i++){
                  this.allFilesArray.push({ id: data[i].id, fileName:data[i].fileName, fileNameWithoutType: this.getFileNameAndtype(data[i].fileName).fileName })   
              }
    }
      fileDownload(file: any) {
          if(!this.mobileView){
            let csvInstance: any = this.$refs.uploadFileComponent;      
            csvInstance[0].downLoadFile(file);
          }


    }
    compareWithBirthDate(detailsObj:any){
        if(detailsObj[0].birthDate.value){
            let birthDate = detailsObj[0].birthDate.value
            birthDate = new Date(birthDate.replace(/\s/g, '').replace(/-/g,'/'))
            return birthDate.toDateString()
        }
    }

    public scholarValidation(input: string, event : any)
    {
        var key=event.key;
        if(input=='firstName'){
        var name=this.scholarPanels[0].firstName.value;
        for(var i=0;i<name.length;i++)
         {
             var numberCheck= APP_UTILITIES.hasOnlyAlphabetDotSpace(name);
             if(!numberCheck)
             {
                this.validatedFields.firstName.alphaCharError =true;               
                return;
             }
             this.validatedFields.firstName.alphaCharError=false;
         }
         if(key=="Backspace")
            this.validatedFields.firstName.alphaCharError=false;
        }
        else if(input=='lastName'){
            var LastName=this.scholarPanels[0].lastName.value;
            for(var j=0;j<LastName.length;j++)
            {
                var numberCheck2= APP_UTILITIES.hasOnlyAlphabetDotSpace(LastName);
                if(!numberCheck2)
                {
                    this.validatedFields.lastName.alphaCharError=true;
                    return;
                }
                this.validatedFields.lastName.alphaCharError=false;
            } 
            if(key=="Backspace")
                this.validatedFields.lastName.alphaCharError=false;
        }
    }

    public familyValidation(input: string, index: number, event : any)
    {
        var key=event.key;
        if(input=='familyFirstName'){
            var name=this.scholarPanels[APP_CONST.FIVE].families[index].firstName.value;
            for(var i=0;i<name.length;i++)
            {
                var numberCheck= APP_UTILITIES.hasOnlyAlphabetDotSpace(name);
                if(!numberCheck)
                {
                   this.scholarPanels[APP_CONST.FIVE].families[index].firstName.errorStatus =true;
                   return;
                }
                this.scholarPanels[APP_CONST.FIVE].families[index].firstName.errorStatus=false;
            }
            if(key=="Backspace")
                this.scholarPanels[APP_CONST.FIVE].families[index].firstName.errorStatus=false;
        }
        else if(input=='familyLastName'){
            var lastName=this.scholarPanels[APP_CONST.FIVE].families[index].lastName.value
            for(var j=0;j<lastName.length;j++)
            {
                var numberCheck2= APP_UTILITIES.hasOnlyAlphabetDotSpace(lastName);
                if(!numberCheck2)
                {
                    this.scholarPanels[APP_CONST.FIVE].families[index].lastName.errorStatus=true
                    return;
                }
                this.scholarPanels[APP_CONST.FIVE].families[index].lastName.errorStatus=false;
            } 
            if(key=="Backspace")
                this.scholarPanels[APP_CONST.FIVE].families[index].lastName.errorStatus=false;
        }
    }
  
    public emergencyContactValidation(input: string, index: number, event : any)
    {
        var key=event.key;
        if(input=='emergencyContactFirstName'){
            var name=this.scholarPanels[APP_CONST.SIX].emergencyContacts[index].firstName.value;
            for(var i=0;i<name.length;i++)
            {
                var numberCheck= APP_UTILITIES.hasOnlyAlphabetDotSpace(name);
                if(!numberCheck)
                {
                   this.scholarPanels[APP_CONST.SIX].emergencyContacts[index].firstName.errorStatus =true;
                   return;
                }
                this.scholarPanels[APP_CONST.SIX].emergencyContacts[index].firstName.errorStatus=false;
            }
            if(key=="Backspace")
                this.scholarPanels[APP_CONST.SIX].emergencyContacts[index].firstName.errorStatus=false;
        }
        else if(input=='emergencyContactLastName'){
            var lastName=this.scholarPanels[APP_CONST.SIX].emergencyContacts[index].lastName.value
            for(var j=0;j<lastName.length;j++)
            {
                var numberCheck2= APP_UTILITIES.hasOnlyAlphabetDotSpace(lastName);
                if(!numberCheck2)
                {
                    this.scholarPanels[APP_CONST.SIX].emergencyContacts[index].lastName.errorStatus=true
                    return;
                }
                this.scholarPanels[APP_CONST.SIX].emergencyContacts[index].lastName.errorStatus=false;
            } 
            if(key=="Backspace")
                this.scholarPanels[APP_CONST.SIX].emergencyContacts[index].lastName.errorStatus=false;
        }
     }

    birthDateChanges(dateVal:string, event:any){
        if(event.key == "Backspace"){
            this.birthDateErrorType = ''
            this.birthDateFormatErr = true;
        } else{
            this.birthDateFormatErr = false;
        }
        if(dateVal.length > 0 && event.key != "Backspace"){
            this.dateOnChange(event);
        }
    }

    zipCodeChange(zipVal: string, event: any) {
        if (event.key == "Backspace") {
            this.invalidZipCode=false;
        }
    }

    checkValue(str:string, max:number, month:boolean) {
        if (str.charAt(0) !== '0' || str == '00') {
            var num = parseInt(str);
            if (isNaN(num) || num <= 0 || num > max) {
                num = parseInt(num.toString().charAt(0));
            }
            if(month){
                str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
            } else{
                str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '' : num.toString();
                if(str == ""){
                    str = 0 + num.toString();
                }
            }
        }
        return str;
    }


    dateOnChange(e:any) {
        let dateElement: any = document.querySelector("#dateFormat");
        let input = e.target.value;

        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);

        let checkLength = input.split('-').map(function(v:any) {
            return v.replace(/\D/g, '')
        });

        if(checkLength.length < 3 && checkLength[checkLength.length -1].length > 2){
            input = [input.slice(0, input.length-1) + " - " , input.slice(input.length-1)].join('');
        }

        let values = input.split('-').map(function(v:any) {
            return v.replace(/\D/g, '')
        });

        if (values[0]) values[0] = this.checkValue(values[0], 12, true);
        if (values[1]) values[1] = this.checkValue(values[1], 31, false);

        let output = values.map(function(v:any, i:any) {
            return v.length == 2 && i < 2 ? v + ' - ' : v;
        });

        let dateFormat = output.join('').substr(0, 14);
        
        if(e.key == "Backspace" && ((dateFormat.substr(dateFormat.length - 1) == "-") ||  (dateFormat.substr(dateFormat.length - 1) == " "))){
            dateFormat = dateFormat.substr(0, dateFormat.length - 4)
        }
        
        dateElement.value = dateFormat;
        return dateFormat;
    }


    onblurdate(e:any) {
        let input = typeof(e) == "object" ? e.target.value : e;

        let values = input.split('-').map(function(v:any, i:number) {
            return v.replace(/\D/g, '')
        });

        let inputDate = input.replace(/\s/g, '').replace(/-/g,'/');
        inputDate = new Date(inputDate);
    
        if (values.length == 3) {
            let year:number;
            let month = parseInt(values[0]) - 1;
            let day = parseInt(values[1]);
            if (values[2].length !== 4 || (values[2].length == 4 && (parseInt(values[2]) < 1900 || inputDate > new Date()))){
                this.birthDateFormatErr = true;
                this.birthDateErrorType = 'wrong year';
            }else {
                this.birthDateFormatErr = false;
                this.birthDateErrorType = '';
                year = parseInt(values[2]);


                if(values[0].length == 2 && values[1].length == 2){
                    this.birthDateFormatErr = !this.isValidDate(year, month, day);
                    this.birthDateErrorType = this.birthDateFormatErr ? 'Invalid Date' : '';
                } else {
                    this.birthDateFormatErr = true;
                }
            }
        } else {
            this.birthDateFormatErr = true;
        }
    
    }

    isValidDate(year:number, month:number, day:number) {
        var d = new Date(year, month, day);
        if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
            return true;
        }
        return false;
    }

    getScheduleDaysForStaff(defaultScheduleDays : any){
        let selectedDaysArray;
        if(this.staffRoleId === APP_CONST.SEVEN && this.panelTitle !== 'Edit'){
            this.weekDaysArray = this.updateScheduleDaysOfScholar;
        }
        selectedDaysArray = defaultScheduleDays.toString().split(',');
        selectedDaysArray.forEach((value : any) => {  
            let objIndex = this.weekDaysArray.findIndex(((obj:any) => obj.value == value));
            this.weekDaysArray[objIndex].check = true;
            this.weekDaysArray[objIndex].enableDay = 'Enable';
            this.enableSiteScheduleDaysOfScholar.push(objIndex);
        });
    }
    
    async getSelectedScheduleDays(defaultScheduleDays : any){
        let selectedDaysArray;
        if(this.panelTitle === 'Edit' && defaultScheduleDays){
            this.weekDaysArray = this.updateScheduleDaysOfScholar;
            this.enableSiteScheduleDaysOfScholar.forEach((value : any) => {   
                let objIndex = this.weekDaysArray.findIndex(((obj:any) => obj.id == value));
                this.weekDaysArray[objIndex].enableDay = 'Enable';
            });
            selectedDaysArray = defaultScheduleDays.split(',');
            this.scheduleDays = selectedDaysArray.toString();
        }
        else{
            let programId = this.userRoles[APP_CONST.ZERO].programId;
            let siteId = this.userRoles[APP_CONST.ZERO].siteId;
            let response = await getSiteByProgramId(programId);
            let data = response.data.filter((obj : any )=> obj.siteId == siteId);
            selectedDaysArray = data[0].selectedDays.split(',');
            this.scheduleDays = selectedDaysArray.toString();
        }
        this.getScheduleDaysForStaff(selectedDaysArray);
    }

    public addRemoveScheduleDays(day: string) {
        let objIndex = this.weekDaysArray.findIndex(((obj:any) => obj.value == day));
        this.weekDaysArray[objIndex].check = this.weekDaysArray[objIndex].check? false : true;
        let daysArray: Array<string> = [];
        this.weekDaysArray.some((data : any) => {
            if (data.check === true) {
                daysArray.push(data.value);
            }
        });
        this.scheduleDays = daysArray.join(",");
    }
}            
    
